import { format, isValid } from 'date-fns/esm';
import React, { Component, RefObject, SyntheticEvent } from 'react';
import DayPicker, { DateUtils, DayModifiers, Modifiers } from 'react-day-picker';
import { DayPickerProps } from 'react-day-picker/types/Props';
import { IStylingProps } from '../../../themes/types';
import InputDatepicker from '../../bbcommon/inputs/input-datepicker/input-datepicker';
import Box from '../../fela/Box';
import componentStyles from './datepicker.style';

interface IProps extends IStylingProps {
  id?: string;
  name?: string;
  value?: string | Date | number;
  label?: string;
  required?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  reset?: boolean;
  onReset?: Function;
  error?: boolean;
  errorTextHide?: boolean;
  errorText?: string;
  fromMonth?: Date;
  toMonth?: Date;
  initialMonth?: Date;
  // Disable days in the future after the given date
  disableAfter?: Date;
  disableAfterToday?: boolean;
  // Disable days before the given date
  disableBefore?: Date;
  disableBeforeToday?: boolean;
  onChange?: (date: Date, name?: string) => void;
  // Shorter option to have already formatted date in callback functions
  onChangeFormatted?: (date: string, name?: string) => void;
  // Format of date displayed in the input field.
  // Default: dd/MM/yyyy
  displayFormat?: string;
  // Format of the date passed to callback functions
  // Default: Date object
  returnFormat?: string;
  // Localisation props
  firstDayOfWeek?: DayPickerProps['firstDayOfWeek'];
  months?: DayPickerProps['months'];
  weekdaysShort?: DayPickerProps['weekdaysShort'];
  weekdaysLong?: DayPickerProps['weekdaysLong'];
}

interface IState {
  value?: Date;
  isOpened: boolean;
}

const DATE_FORMAT = 'dd/MM/yyyy';

class Datepicker extends Component<IProps, IState> {
  daypickerRef: RefObject<DayPicker>;

  constructor(props: IProps) {
    super(props);

    const { value } = this.props;

    this.state = {
      value: value ? new Date(value) : undefined,
      isOpened: false,
    };

    this.daypickerRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps) {
    const oldValue = prevProps.value;
    const newValue = this.props.value;
    if (oldValue !== newValue) {
      this.setState({ value: newValue ? new Date(newValue) : undefined });
    }
  }

  handleDayClick = (date: Date, { disabled }: DayModifiers) => {
    const { name, onChange, onChangeFormatted, returnFormat } = this.props;

    if (disabled) {
      return;
    }

    this.setState({ value: date });

    if (onChange) {
      onChange(date, name);
    }

    if (onChangeFormatted && returnFormat) {
      onChangeFormatted(format(date, returnFormat), name);
    }

    this.hide();
  };

  showCurrentDate = (e: SyntheticEvent<any>): void => {
    e.currentTarget.blur(); // fix safari blinking cursor bug
    const { value, isOpened } = this.state;
    if (this.daypickerRef && value) this.daypickerRef.current.showMonth(value);
    this.setState({ isOpened: !isOpened });
  };

  hide = () => {
    this.setState({ isOpened: false });
  };

  render() {
    const {
      label,
      id,
      name,
      required,
      placeholder,
      autoFocus,
      disabled,
      reset,
      error,
      errorTextHide,
      errorText,
      fromMonth,
      toMonth,
      initialMonth,
      disableAfter,
      disableBefore,
      displayFormat,
      disableAfterToday,
      disableBeforeToday,
      onReset,
      firstDayOfWeek,
      months,
      weekdaysShort,
      weekdaysLong,
      ...restProps
    } = this.props;

    const { value, isOpened } = this.state;

    const formattedDate = isValid(value) ? format(value, displayFormat || DATE_FORMAT) : '';

    const modifiers: Partial<Modifiers> = {
      selected: (day) => DateUtils.isSameDay(value, day),
    };

    const styles = componentStyles({ shown: isOpened, label });

    return (
      <Box style={styles.mainWrapper} {...restProps}>
        <Box style={styles.inputWrapper}>
          <InputDatepicker
            readOnlyInteractive
            readOnlyActive
            readOnly
            type="none"
            value={formattedDate}
            placeholder={placeholder}
            onFocus={this.showCurrentDate}
            errorTextHide={errorTextHide || isOpened}
            label={label}
            id={id}
            name={name}
            required={required}
            autoFocus={autoFocus}
            disabled={disabled}
            reset={reset}
            onReset={onReset}
            error={error}
            errorText={errorText}
          />
        </Box>
        <Box style={styles.dayPickerWrap}>
          <DayPicker
            modifiers={modifiers}
            disabledDays={{
              after: disableAfterToday ? new Date() : disableAfter,
              before: disableBeforeToday ? new Date() : disableBefore,
            }}
            ref={this.daypickerRef}
            initialMonth={initialMonth}
            fromMonth={fromMonth}
            toMonth={toMonth}
            onDayClick={this.handleDayClick}
            firstDayOfWeek={firstDayOfWeek ?? 1}
            months={months}
            weekdaysShort={weekdaysShort}
            weekdaysLong={weekdaysLong}
          />
        </Box>
        <Box style={styles.offHide} onClick={this.hide} />
      </Box>
    );
  }
}

export default Datepicker;
